import styled, { css, themeGet, device } from "@styled";
export const HumanSectionWrapper = styled.section`
    padding:40px 0;
    ${device.medium} {
        padding:60px 0;
    }
    ${device.large} {
        padding:80px 0;
    }
    ${device.xxlarge} {
        padding:100px 0;
    }  
    ${(props) =>
        props.layout === 1 &&
        css`
        padding:40px 0 0;
        .section-title{
            margin-bottom:30px;
        } 
        ${device.medium} {
            padding:60px 0 0;
            .section-title{
                margin-bottom:50px;
            } 
        }
        ${device.large} {
            padding:80px 0 0;
            .section-title{
                margin-bottom:80px;
            } 
        }
        ${device.xxlarge} {
            padding:100px 0 0;
            .section-title{
                margin-bottom:100px;
            } 
        }   
    `}
`;
export const HumanImage = styled.div`
    
`;