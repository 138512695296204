import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import SectionTitle from "@ui/section-title";
import Image from "@ui/image";
import {HumanSectionWrapper, HumanImage} from "./style"
const HumanInTheLoop = ({data, layout, ...props}) =>{
    return (
        <HumanSectionWrapper layout={layout} {...props} id={data.section}>
            <Container>
            <SectionTitle
                title={data.section_title?.title}
                subtitle={data.section_title?.subtitle}
                description={data.section_title?.description}
            />
            {data.images.map((data , index) => (
                <HumanImage key={index}>
                    <Image src={data.src} alt={data.alt} title={data.alt} />
                </HumanImage>
            ))}
            </Container>
        </HumanSectionWrapper>
    )
}
HumanInTheLoop.propTypes = {
    items: PropTypes.object,
};
  
export default HumanInTheLoop;